<template>
    <v-card
        class="pa-6 mt-4 mx-auto" 
        width="500"
    >  
        <v-row 
            no-gutters justify="center" 
            align="center"
            v-if="fileInfos.length == 0"
        >
            <v-col>
                <v-file-input
                    show-size
                    :label="fileLabel"
                    @change="selectFile"
                    :accept="fileAccept"
                    placeholder="Click to upload file"
                    :disabled="!editRegistration"
                    :filled="!editRegistration" 
                ></v-file-input>
                <div class="text-caption red--text">Only {{ fileTypeAllowed }} files allowed</div>
            </v-col>
        </v-row>

        <v-row
            v-if="currentFile"
        >
            <v-progress-linear
                v-model="progress"
                color="light-blue"
                height="25"
                reactive
            >
                <strong>{{ progress }} %</strong>
            </v-progress-linear>
        </v-row>

        <v-alert v-if="message" border="left" color="blue-grey" dark>
            {{ message }}
        </v-alert>

        <v-row
            v-if="fileInfos.length > 0"           
        >
            <v-col
                v-for="(file, index) in fileInfos"
                :key="index"                
                elevation="0"
            >
                
                <v-btn
                    text
                    outlined
                    @click="displayFilePreview(file)"
                    block                        
                >
                    {{ file.name }}
                </v-btn> 
            </v-col>
            <v-col
                offset="5"
            >               
                <v-btn                        
                    text
                    outlined
                    small
                    @click="removeFile()"
                    class="mr-3"
                >
                    Remove File
                </v-btn>

                <v-btn                        
                    text
                    outlined
                    small
                    @click="resubmitFile"
                >
                    Resubmit File
                </v-btn>
                
            </v-col>
            
        </v-row>

        <v-dialog
            v-model="filePreview"
            :max-width="maxWidth"
            persistent
        >   
            <v-card
                v-if="!photoPreview"
                height="80vh" 
            >
                <iframe ref="pdf" style="width:100%; height:100%" frameBorder="0" :src="src"></iframe>                
            </v-card>

            <v-img
                v-else
                max-width="200"
                :src="src"
                class="mx-auto ma-6"
            ></v-img>

            <v-container
                fluid
                class="pa-0"
            >
                <v-sheet>
                    <v-row
                        justify="end"
                        class="ma-0"
                    >
                        
                        <v-btn                                            
                            color="primary"
                            dark
                            @click="closeFilePreview"                                            
                            class="my-2 mr-4"
                        >
                            Close
                        </v-btn>
                        
                    </v-row>
                </v-sheet>
            </v-container>                        
        </v-dialog>
    </v-card>
</template>

<script>
import UploadService from "../services/UploadFileService";
import { mapActions, mapMutations, mapGetters } from 'vuex';

export default {
    name: "upload-files",

    props: {
        fileType: String,
        fileLabel: String,
        fileTypeAllowed: String,
        fileAccept: String,
    },

    // mounted: function (){
    //    this.initialize();
    // },
    
    computed: {
        ...mapGetters({
            record: 'auth/getUser',
            getStudentRecord: 'auth/getStudentRecord',
            editRegistration: 'auth/getEditRegistration',
        })
        
    },

    watch: {
        getStudentRecord : {
            handler () {
                this.initialize();
            },
        }
    },

    data: () => ({
        currentFile: undefined,
        progress: 0,
        message: "",
        fileInfos: [],
        id: null,
        filePreview: false,
        photoPreview: false,
        src: null,
        maxWidth: '',
    }),

    methods: {
        ...mapActions({
            getRecord: 'dataFiles/getRecord',
            deleteFile: 'dataFiles/deleteFile',
        }),

        ...mapMutations({
            setOverlay: 'auth/setOverlay',
            setDeleteFile: 'dataFiles/setDeleteFile'
        }),

        async initialize () {
            this.setOverlay(true);
            try {
                //let response = await this.getRecord();                
                this.id = this.record.student_id;
                await UploadService.getFiles(this.id).then(response => {
                    this.fileInfos = response.data.filter(file => file.type == this.fileType);                    
                    //this.setOverlay(false);                    
                }).catch(error => {
                    if(error.response) console.log(error.response)
                    else console.log(error);
                })

            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);                
            }
            this.setOverlay(false);            
        },

        selectFile (file) {
            this.progress = 0;
            this.currentFile = file;
            this.upload();
        },

        upload () {
            if (!this.currentFile) {
                this.message = "Please select a file!";
                return;
            }

            this.message = "";            
            console.log(this.id)
            UploadService.upload(this.currentFile, this.id, this.fileType, (event) => {
                this.progress = Math.round((100 * event.loaded) / event.total);
            })
                .then((response) => {
                    console.log(response);
                    this.currentFile = undefined;
                    this.message = response.data.message;
                    //console.log(this.id);                    
                    return UploadService.getFiles(this.id);
                })
                .then((files) => {
                    console.log(files);
                    console.log(this.fileType);
                    this.fileInfos = files.data.filter(file => file.type == this.fileType);
                    console.log(this.fileInfos);
                    //this.message = "File sucessfullly uploaded!";
                })
                .catch((error) => {
                    if(error.response) console.log(error.response);
                    else console.log(error);
                    this.progress = 0;
                    this.message = "Could not upload the file!";
                    this.currentFile = undefined;
                } )
        },

        displayFilePreview (file) {
            console.log(file);
            this.src = file.url;
            console.log(this.src)
            this.maxWidth = "90vw";

            if(file.type != 'picture'){
                this.photoPreview = false;
            }            
            else{
                this.photoPreview = true;
                this.maxWidth = "300"
            } 

            this.filePreview = true;
        },

        closeFilePreview () {
            this.filePreview = false;
            this.src = null;
        },

        resubmitFile () {
            this.fileInfos = [];
            this.currentFile = undefined;
            this.message = null;
        },

        async removeFile () {
            const { name: fileName, type: fileType } = this.fileInfos[0];
            this.setDeleteFile({fileName, fileType});
            try {
                const { statusText } = await this.deleteFile();
                console.log(statusText)
                this.fileInfos = [];
                this.currentFile = null;
                this.message = null;
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.message = "Error Occured. File Could not be deleted"
            }
        }
    }
}
</script>