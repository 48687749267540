import axios from 'axios'

export default{
    namespaced: true,

    state: {
        //
    },

    getters: {
        //
    },

    mutations: {
       //
    },

    actions: {
        getAttendanceRecords({ rootGetters }){
            const url = '/api/student-attendance/' + rootGetters['auth/getUserID'];
            return axios.get(url);
        }
    },
}