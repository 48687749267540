<template>
    <layout>
         <v-row 
            align="center"
            justify="center"
        > 
            <v-card               
                width="500"
            >
                <v-card-title :class="cardTitleClass">
                    HEALTH DATA
                </v-card-title>  
                              
            </v-card>            
         </v-row>

         <v-row 
            align="center"
            justify="center"
            v-if="immunization"
        > 
            <v-card
                class="mt-4 pa-6" 
                width="500"
            >  
                 
                    <v-toolbar-title class="title pl-0">
                        IMMUNIZATION RECORDS
                    </v-toolbar-title>
               
                
                    <v-toolbar-title class="body-2 pl-0 text-wrap mt-5">
                        Please indicate whether your child was immunized for the following
                    </v-toolbar-title>
                
                <v-row 
                    align="center"
                    justify="center"
                    v-for="field in immunizationRecords"
                    :key="field.label"
                                        
                >
                    <v-col
                        cols="12"                        
                    >
                        <v-checkbox
                            v-bind:label="field.label"
                            v-model="healthRecord[field.model]"
                            @change="checkBoxUpdate(immunizationRecords, field.model)"
                            dense
                            hide-details
                            :disabled="!editRegistration"
                            :filled="!editRegistration" 
                        >
                                <template v-slot:append>
                                    <SaveStatus :save-status="saveStatusValue(field)" />
                                </template>
                        </v-checkbox>
                    </v-col>
                </v-row>
            </v-card>                      
         </v-row>        

        <v-row 
            align="center"
            justify="center"
        > 
            <v-card
                    class="mt-4" 
                    width="500"
                >
                <v-card-title :class="cardTitleClass">
                    HEALTH CONDITIONS
                </v-card-title> 
                
                <v-app-bar
                    flat
                    color="white"                    
                >
                    <v-toolbar-title class="body-2 pl-0 text-wrap">                        
                        Please indicate whether your child suffers from any of the following  
                    </v-toolbar-title>
                </v-app-bar>
                <v-row 
                    align="center"
                    justify="center"
                    v-for="field in healthConditions"
                    :key="field.label" 
                    class="px-4"                                         
                >
                    <v-col
                        cols="12"                        
                    >
                        <v-checkbox
                           v-bind:label="field.label"
                            v-model="healthRecord[field.model]"
                            @change="checkBoxUpdate(healthConditions, field.model)"
                            dense
                            hide-details
                            :disabled="!editRegistration"
                            :filled="!editRegistration" 
                        >
                            <template v-slot:append>
                                <SaveStatus :save-status="saveStatusValue(field)" />
                            </template>
                        
                        </v-checkbox>
                    </v-col>
                </v-row>
            </v-card>
        </v-row>

        <v-row 
            align="center"
            justify="center"
        > 
            <v-card
                class="pa-6 mt-4" 
                width="500"
            >
                <v-row 
                    align="center"
                    justify="center"
                    v-for="field in fields"
                    :key="field.label"
                >
                    <v-col
                        cols="12"                        
                    >
                        <v-text-field
                            v-bind:label="field.label"
                            v-model="studentRecord[field.model]"
                            :placeholder="field.placeholder"
                            @blur="updateStore(fields, field.model)"
                            autocomplete="false"
                            :disabled="!editRegistration"
                            :filled="!editRegistration" 
                        >
                            <template v-slot:append>
                               <SaveStatus :save-status="saveStatusValue(field)" />
                            </template>
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-card> 
        </v-row>

    </layout>    
</template>

<script>
import Layout from './layouts/Registration';
import SaveStatus from './AutoSaveStatus.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    name: 'HealthData',
    components: {
        Layout,
        SaveStatus,
    },
    data: () => ({
        fields: [
            {
                label: 'Other Health Condition', 
                type: 'text', 
                placeholder: 'State any other health condition', 
                model: 'medical_history',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Serious Illness', 
                type: 'text', 
                placeholder: 'State any serious illness', 
                model: 'other_illness',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Allergies', 
                type: 'text', 
                placeholder: 'State any allergies', 
                model: 'allergy',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
        ],
        immunizationRecords: [
            {
                label: 'Hepatitis', 
                model: 'hepatitis',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Polio', 
                model: 'polio',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Diphtheria', 
                model: 'diphtheria',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Tetanus', 
                model: 'tetanus',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Yellow Fever', 
                model: 'yellow_fever',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Measles', 
                model: 'measles',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'T.B.', 
                model: 'tb',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Chicken Pox', 
                model: 'chicken_pox',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Typhoid', 
                model: 'typhoid',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Rheumatic Fever', 
                model: 'rheumatic_fever',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
        ],
        healthConditions: [
            {
                label: 'Poor Eyesight', 
                model: 'poor_eyesight',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Poor Hearing', 
                model: 'poor_hearing',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Diabetes', 
                model: 'diabetes',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Asthma', 
                model: 'asthma',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            {
                label: 'Epilepsy', 
                model: 'epilepsy',
                save: false,
                saveSuccess: false,
                saveError: false,
            },
            // {label: 'Heart Related Illness', model: 'heart_condition'},
            // {label: 'Skin Disorder', model: 'skin_disorder'},
            // {label: 'Depression', model: 'depression'},
            // {label: 'Anxiety', model: 'anxiety'},            
        ],
        learningDisabilites: [
            {label: 'Dyslexia', model: 'dyslexia'},
            {label: 'Dyscalculia', model: 'dyscalculia'},
            {label: 'Dysgraphia', model: 'dysgraphia'},
            {label: 'Non Verbal learning disorder', model: 'non_verbal'},
            {label: 'Apraxia of speech', model: 'apraxia'},
            {label: 'Attention deficit disorder', model: 'attention_deficit'},
            {label: 'Attention deficit hyperactivity disorder', model: 'adhd'},
            {label: 'Autism', model: 'autism'},
            {label: 'Cerebral palsy', model: 'cerebral_palsy'},
            {label: 'Other', model: 'other'},
        ],
        learningChallenges: false,
        immunization: true,
        studentRecord: {},
        otherLearningDisablity: false,
        otherDisablity: '',       
        healthRecord: {
            hepatitis: false,
            polio: false,
            diphtheria: false,
            tetanus: false,
            yellow_fever: false,
            measles: false,
            tb: false,
            chicken_pox: false,
            typhoid: false,
            rheumatic_fever: false,
            poor_eyesight: false,
            poor_hearing: false,
            diabetes: false,
            asthma: false,
            epilepsy: false,
        }  
    }),

    // mounted: function () {
    //     this.setStudentRecord();
    //     this.setHealthRecord();        
    // },

    computed: {
        ...mapGetters({
            getStudentRecord: 'auth/getStudentRecord',
            editRegistration: 'auth/getEditRegistration',
        }),

        cardTitleClass(){
            return "secondary--text pl-4 primary";
        },    
    },
    
    watch: {
        getStudentRecord : {
            handler () {
                this.initialize();
            },
        }
    },

    methods: {
        initialize () {
            this.setStudentRecord();
            this.setHealthRecord(); 
        },

        saveStatusValue ({ save, saveSuccess, saveError }) {
            if(save) return 'saving';
            if(saveSuccess) return 'success';
            if(saveError) return 'error';
            return '';
        },

        setStudentRecord(){
            this.studentRecord = {...this.getStudentRecord}
        },
        setHealthRecord(){
            for(const [key] of Object.entries(this.healthRecord)){
               if(this.studentRecord[key] == 1){
                   this.healthRecord[key] = true;
               }
               else{
                   this.healthRecord[key] = false;
               }                           
           }
        },
        ...mapMutations({
            updateStudentRecord: 'auth/setStudentRecord',            
        }),
        ...mapActions({
            saveRecord: 'auth/saveRecord',
        }),

        async updateStore(array, model){
            await this.setSaveStatus(array, model);            
            this.updateStudentRecord(this.studentRecord);
            try {
                await this.saveRecord();
                this.setSaveStatus(array, model, 'saved');
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.setSaveStatus(array, model, 'error');
            }            
        },

        checkBoxUpdate(array, model){            
            for(const [key, value] of Object.entries(this.healthRecord)){
                if(key == 'other'){
                    if(this.healthRecord.other){
                            this.otherLearningDisablity = true;
                    }
                    else{
                        this.otherLearningDisablity = false;
                    }                   
                }
                else if(value){
                    this.studentRecord[key] = 1;
                }
                else{
                    this.studentRecord[key] = 0;
                }
            }
            this.updateStore(array, model);
        },

        setSaveStatus (array, model, status = 'saving') {
            for(const field of array) {
                if(field.model == model){
                    field.save = false;
                    field.saveSuccess = false;
                    field.saveError = false;
                }
            }
            
            for(const field of array) {                
                if(field.model == model){                    
                    switch (status){
                        case 'saving':                            
                            return new Promise(resolve => {
                                setTimeout(() => {
                                    field.save = true;                                                                      
                                    resolve(status);
                                }, 600)
                            })                            
                            
                        case 'saved':
                            return new Promise(resolve => {
                                setTimeout(() => {
                                    field.saveSuccess = true; 
                                    resolve(status);
                                }, 600)
                            })
                            
                        case 'error':
                            return new Promise(resolve => {
                                setTimeout(() => {
                                    field.saveError = true;
                                    resolve(status);
                                }, 600)
                            })
                            
                        default:
                            console.log('no match found');
                            return new Promise(resolve => {
                                resolve('no match');
                            });             
                    }                    
                }
            }
        }    
    }
}
</script>