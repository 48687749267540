import axios from 'axios'

export default{
    namespaced: true,
    state: {        
        record: {}, 
        deleteFile: {}, 
    },

    getters: { 
        getRecord (state) {
            return state.record
        },
        
        getDeleteFile (state) {
            return state.deleteFile
        }
    },

    mutations: { 
        setRecord (state, value) {
            state.record = value
        },
        
        setDeleteFile (state, value) {
            state.deleteFile = value
        }
    },

    actions: {
        getRecord ({ rootGetters }) {
            const url = '/api/student-data-files/' + rootGetters['auth/getUser'].student_id;
            console.log(url)
            return axios.get(url);
        },
        
        postRecord ({ getters }) {
            const url = '/api/student-data-files';
            return axios.post(url, getters.getRecord);
        },

        deleteFile ({ rootGetters, getters }) {
            const url = '/api/file';
            return axios.delete(url, {
                params: {
                    id: rootGetters['auth/getUser'].student_id,
                    fileName: getters.getDeleteFile.fileName,
                    fileType: getters.getDeleteFile.fileType,
                }
            })
        }
    }
}