<template>
    <layout>
        <v-row 
            align="center"
            justify="center"
        > 
            <v-card
                class="pa-6" 
                width="500"
            >
                <v-row>
                    <p class="font-weight-light">
                        I agree with and will abide by all the School's policies,
                        regulations and rules which are detailed in the School's Handbook and including
                        the Standards of Behaviour for Online learning. I will ensure my child 
                        conforms to the standards of work and conduct expected, both within
                        and outside the confines of the school while attending {{ schoolName }}.
                        Additionaly, I will make every effort to have my child uphold the Ministry's 
                        School Code of conduct.
                    </p>
                    <v-checkbox
                        label="I Agree"
                        v-model="agree"
                        @change="updateStore"
                        color="primary"                        
                    ></v-checkbox>
                </v-row>

                <v-row 
                    align="center"
                    justify="center"
                    v-show="printButton"                   
                >
                    <v-col
                        cols="12"
                        class="pl-0"                        
                    >
                        <v-btn
                            dark
                            color="primary"
                            @click="generatePDF"
                        >                            
                            <v-icon left>mdi-printer</v-icon>
                            Generate Registration Form
                        </v-btn>
                        <div 
                            class="mt-4 text-caption font-weight-light"                        
                        >8 1/2 x 14 (Legal) Sheet Required</div>
                    </v-col>
                </v-row>
            </v-card>
        </v-row>
        
        <v-dialog
            v-model="registrationForm" 
            fullscreen
            transition="dialog-bottom-transition"
        >   
            <v-card
                height="100vh"
            >
                <v-toolbar
                    dark
                    color="primary"
                    height="30"
                >
                    <v-spacer></v-spacer>
                    <v-btn
                        icon
                        small
                        dark
                        @click="closeRegistrationForm"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <iframe ref="pdf" style="width:100%; height:94%" frameBorder="0" :src="src"></iframe>                
            </v-card>
                            
        </v-dialog>
           
    </layout>
</template>

<script>
import Layout from './layouts/Registration';
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    name: 'Complete',
    components: {
        Layout,
    },

    data: () => ({
        printButton: true,        
        studentRecord: {},
        agree: false,
        registrationForm: false,
        iframe: null,        
        src: ""
    }),

    watch: {
        getStudentRecord : {
            handler () {
                this.initialize();
            },
        }
    },    
    computed: {
        ...mapGetters({
            getStudentRecord: 'auth/getStudentRecord',
            termsAndConditions: 'form/getTermsAndConditions',
            primaryColor: 'form/getPrimaryColor',
            colorDarken: 'form/getColorDarken',
            //registrationFormURI: 'auth/getRegistrationForm',
            student: 'auth/getUser',
            schoolName: 'form/getSchoolName',
        }),

        getAgree(){
            if(this.termsAndConditions == 1) return true;
            return false;
        },

        themeColor(){          
            return this.primaryColor + ' ' + this.colorDarken;
        }, 

    },
    methods: {
        initialize () {
            this.setStudentRecord();
            this.setAgree();
            this.setRegistrationForm();
        },

        ...mapMutations({
            updateStudentRecord: 'auth/setStudentRecord',
            setTermsAndCondtions: 'form/setTermsAndConditions',
        }),
        ...mapActions({
            saveRecord: 'auth/saveRecord',
            getRegistrationForm: 'auth/getRegistrationForm'
        }),
        updateStore(){
            if(this.agree){
                this.studentRecord.agree_terms_conditions = 1;
            }
            else{
                this.studentRecord.agree_terms_conditions = 0;
            }            
            this.updateStudentRecord(this.studentRecord);
            console.log(this.studentRecord);
            this.saveRecord()
                .then(response => {
                    console.log(response.status);
                })
                .catch(error =>{
                    console.log(error)
                    //this.saveError = true;
                });
        },
        setStudentRecord(){
            this.studentRecord = {...this.getStudentRecord}            
        },                
        setAgree(){
            //console.log(this.getAgree);
            if(this.getAgree == 1) this.agree = true;
            else this.agree = false;
        },
        generatePDF(){
           this.registrationForm = true;
           //console.log(this.registrationFormURI);
        },
        setRegistrationForm(){
            this.src = process.env.VUE_APP_API_URI + '/api/registration-form?student_id=' + this.student.student_id;
            //console.log(this.registrationFormURI)            
        },
        closeRegistrationForm(){            
            this.registrationForm = false
        },
    }
}
</script>