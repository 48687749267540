import { render, staticRenderFns } from "./Registration.vue?vue&type=template&id=2a04d98e&scoped=true"
import script from "./Registration.vue?vue&type=script&lang=js"
export * from "./Registration.vue?vue&type=script&lang=js"
import style0 from "./Registration.vue?vue&type=style&index=0&id=2a04d98e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a04d98e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VBottomNavigation,VList,VListItem,VListItemContent,VListItemTitle,VNavigationDrawer})
