import axios from 'axios'

export default {
    namespaced: true,

    state: {
        authenticated: sessionStorage.authenticated || false,
        admin: sessionStorage.admin || false,
        userID: sessionStorage.userID || null,
        user: {},        
        userRecord: null,
        studentRecord: null,
        overlay: false,
        dialog: false,
        loginErrors: '',
        resetPassword: false,
        newPassword: '',
        displayPasswordChanged: false,
        validStudentID: false,
        step: 1,        
        passwordHint: '',
        passwordPlaceholder: '', 
        firstLogin: sessionStorage.getItem('first_login') || 0,  
        editRegistration: false,
        studentFormLevel: null,       
    },

    getters: {
        getAuthenticated (state) {
            return state.authenticated
        },

        getUserID (state) {
            return state.userID
        },      

        getUserRecord(state){
            return state.userRecord
        },

        getOverlay(state){
            return state.overlay
        },

        getStudentRecord(state){
            return state.studentRecord
        },

        getDialog(state){
            return state.dialog
        },

        getLoginErrors(state){
            return state.loginErrors
        },

        getResetPassword(state){
            return state.resetPassword
        },

        getNewPassword(state){
            return state.newPassword
        },

        getDisplayPasswordChanged(state){
            return state.displayPasswordChanged
        },

        getStudentIDValidation(state){
            return state.validStudentID
        },

        getStep(state){
            return state.step
        },
        
        getPasswordHint(state){
            return state.passwordHint
        },

        getPasswordPlaceholder(state){
            return state.passwordPlaceholder
        },           

        getAdmin(state){
            return state.admin
        },

        getUser (state) {
            return state.user
        },

        getFirstLogin (state) {
            return state.firstLogin
        },

        getEditRegistration (state) {
            return state.editRegistration
        },

        getStudentFormLevel (state) {
            return state.studentFormLevel
        },

    },

    mutations: {
        setAuthenticated (state, value) {
            state.authenticated = value
        },

        setUserID (state, value) {
            state.userID = value
        },

        setUserRecord (state, value){
            state.userRecord = value
        },

        setOverlay (state, value){
            state.overlay = value
        },

        setStudentRecord(state, value){
            state.studentRecord = value
        },

        setDialog(state, value){
            state.dialog = value
        },

        setLoginErrors(state, value){
            state.loginErrors = value
        },

        setResetPassword(state, value){
            state.resetPassword = value
        },

        setNewPassword(state, value){
            state.newPassword = value
        },

        setDisplayPasswordChanged(state, value){
            state.displayPasswordChanged = value
        },

        setStudentIDValidation(state, value){
            state.validStudentID = value
        },

        setStep(state, value){
            state.step = value
        },

        setPasswordHint(state, value){
            state.passwordHint = value
        },

        setPasswordPlaceholder(state, value){
            state.passwordPlaceholder = value
        },

        setAdmin(state, value){
            state.admin = value
        },

        setUser (state, value) {
            state.user = value
        },

        setFirstLogin (state, value) {
            state.firstLogin = value
        },

        setEditRegistration (state, value) {
            state.editRegistration = value
        },

        setStudentFormLevel (state, value) {
            state.studentFormLevel = value
        },
    },

    actions: {
        async signIn ({ commit }, credentials){                      
            commit('setOverlay', true); 
            try {
                await axios.get('/sanctum/csrf-cookie');
                const promiseLogin = axios.post('/api/login-student', credentials);
                const promiseUser = axios.get(`/api/user?student_id=${credentials.student_id}`);

                const responses = await Promise.all([promiseLogin, promiseUser]);
                const [{ data: dataLogin }, { data: dataUser }] = responses;  
                commit('setAuthenticated', true);
                commit('setUserID', dataUser.student_id);
                commit('setLoginErrors', '');
                commit('setFirstLogin', dataUser.first_login)

                sessionStorage.authenticated = dataLogin.name;                
                sessionStorage.userID = dataUser.student_id;
                sessionStorage.setItem('first_login', dataUser.first_login);

                if(dataLogin.name == 'Admin'){
                    commit('setAdmin', true);
                    sessionStorage.admin = dataLogin.name;
                    console.log('admin signed in')
                }
                
                if(dataUser.password_reset == 1){
                    commit('setResetPassword', true);
                    commit('setPasswordHint', 'yyyymmdd');
                    commit('setPasswordPlaceholder', 'Enter date of birth');  
                }
                else{
                    commit('setResetPassword', false);
                    commit('setPasswordHint', '');
                    commit('setPasswordPlaceholder', 'Enter password')                         
                } 
                
            } catch (error) {                
                if(error.response && !error.response.data.errors){
                    console.log(error.response);
                    commit('setLoginErrors', error.response.data.message); 
                }
                else if(error.response && error.response.data.errors){
                    commit('setLoginErrors', error.response.data.errors.message[0])
                }                
                else console.log(error);

                commit('setAuthenticated', false);
                commit('setOverlay', false);  
            } 
        },

        async signInFirstLogin ({ commit }, credentials){                      
            commit('setOverlay', true); 
            
            try {
                await axios.get('/sanctum/csrf-cookie');
                const promiseLogin = axios.post('/api/login-student', credentials);
                const { data: dataLogin } = await promiseLogin;
                
                const promiseUser = axios.get('/api/user/' + dataLogin.student_id);
                const { data: dataUser } = await promiseUser;
                
                commit('setAuthenticated', true);
                commit('setUserID', dataUser[0].student_id);
                commit('setLoginErrors', '');
                commit('setFirstLogin', dataLogin.first_login)

                sessionStorage.authenticated = dataLogin.name;                
                sessionStorage.userID = dataUser[0].student_id;
                sessionStorage.setItem('first_login', dataLogin.first_login);

                if(dataUser[0].password_reset == 1){
                    commit('setResetPassword', true);
                    commit('setPasswordHint', 'yyyymmdd');
                    commit('setPasswordPlaceholder', 'Enter date of birth');  
                }
                else{
                    commit('setResetPassword', false);
                    commit('setPasswordHint', '');
                    commit('setPasswordPlaceholder', 'Enter password')                         
                } 
                
            } catch (error) {                
                if(error.response && !error.response.data.errors){
                    console.log(error.response);
                    commit('setLoginErrors', error.response.data.message); 
                }
                else if(error.response && error.response.data.errors){
                    commit('setLoginErrors', error.response.data.errors.message[0])
                }                
                else console.log(error);

                commit('setAuthenticated', false);
                commit('setOverlay', false);  
            } 
        },

        async adminSignIn({ commit, dispatch }, credentials){
            commit('setOverlay', true);
            try{
                await axios.get('/sanctum/csrf-cookie');
                let response = await axios.post('/api/admin-login', credentials);
                console.log(response.status);
                sessionStorage.authenticated = response.data;
                sessionStorage.admin = response.data;
                commit('setLoginErrors', '')
                commit('setAuthenticated', true);
                commit('setUser', credentials);
                commit('setOverlay', false);
            }
            catch(error){
                dispatch('serverError', error)
                if(error.response) console.log(error.response);
                else console.log(error);
            }                  
        },

        async getStudent({ commit, getters}){  
            let url = `/api/student-record?student_id=${getters.getUserID}`;            
            return axios.get(url)
                .then(response => {
                    commit('setStudentRecord', response.data);
                    let userInitials = response.data.first_name.charAt(0)+response.data.last_name.charAt(0);
                    let userName = response.data.first_name+' '+response.data.last_name;
                    commit('setUser', {
                        student_id: response.data.id,
                        name: userName,
                        initials: userInitials
                    })
                    // console.log(getters.getUser);
                    commit('setOverlay', false);
                    //console.log(`Action user Authenticated: ${getters.getAuthenticated}`)
                })
                .catch(error => {                    
                    commit('setUser', null)
                    console.log(error)
                })
                
        },       

        async changePassword({ getters, commit }){
            commit('setOverlay', true);
            console.log(`Password: ${getters.getNewPassword}`);
            console.log(`Student Id: ${getters.getUser.student_id}`);
            let url = '/api/change-password-student';
            return await axios.post(url, {
                "student_id" : getters.getUser.student_id,
                "password" : getters.getNewPassword,
            });                   
        },        

        async signOut({commit}){
            try {
                let response = await  axios.post('/logout');
                console.log(response.status);                
            } catch (error) {
                if(error.response) console.log(error.response);
                console.log(error);                
            }
            commit('setAuthenticated', false); 
            sessionStorage.clear();  
        },
        
        async saveRecord({getters}){
            let studentRecord = getters.getStudentRecord;            
            return axios.post('/api/student', studentRecord);
        },

       checkSession({dispatch}){           
           console.log(`Cookie Set: ${dispatch('cookieSet', 'ans_api_session')}`);
       },

       async getStudents(){
           return await axios.get('/api/students')
       },

       async resetPassword({ commit, getters }, resetPassword){
            commit('setOverlay', true);
            await axios.post('/api/change-password-student',{
                "student_id" : getters.getUser.id,
                "reset_password" : resetPassword,
            });
            commit('setResetPassword', resetPassword);
            commit('setDisplayPasswordChanged', true);
            commit('setOverlay', false);
       },

       cookieSet(cookieName){
           let name = cookieName + "=";
           let decodedCookie = decodeURIComponent(document.cookie);
           let cookies = decodedCookie.split(';');
           for(var i = 0; i < cookies.length; i++){
               var c = cookies[i];
               while(c.charAt(0) == ' '){
                   c = c.substring(1);
               }
               if(c.indexOf(name) == 0){
                    return true;
               }
           }
           return false;
       },

        serverError({ commit }, err ){
                console.log(err.response)
                var hasErrors = Object.prototype.hasOwnProperty.call(err.response, 'errors');                                     
                if( hasErrors && err.response.data.errors.message.length != 0){
                    commit('setLoginErrors', err.response.data.errors.message[0]);
                }
                else{
                    commit('setLoginErrors', err.response.statusText);
                }                    
                commit('setAuthenticated', false);
                commit('setOverlay', false);
        },

        getRegistrationStatus ({ getters}) {
            const url = `/api/student-registration` ;
            return axios.get(url, {
                params: {
                    class_level: getters.getStudentFormLevel
                }
            });
        },

        getFormClasses () {
            const url = '/api/form-classes-list';
            return axios.get(url);
        },
    }
}