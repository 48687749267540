<template>
    <v-app>    
        <v-main>
            <v-container 
                class="fill-height justify-center" 
                fluid

            >
                <v-row 
                    align="center" 
                    justify="center" 
                    style="z-index:10"
                    class="my-auto"
                >
                    <v-col cols="12">
                        <v-expand-transition>
                            <v-card 
                                width="400"
                                v-show="expand"
                                class="elevation-12 mx-auto pa-4"
                            >                            
                                <v-row
                                    justify="center"
                                >
                                    <v-img
                                        src="../../assets/logo.png"
                                        max-width="100"
                                        contain
                                        class="d-flex"
                                    ></v-img>
                                </v-row>
                                <v-row no-gutters> 
                                    <v-col cols="12">
                                        <div 
                                            class="pa-4"
                                            v-if="getloginErrors"
                                        >
                                            <v-alert                                                    
                                                type="error"
                                                outlined                                                    
                                                
                                                class="red--text"
                                            >
                                                {{ getloginErrors }}
                                            </v-alert>    
                                        </div>                                       
                                        
                                        <div class="text-center font-weight-light primary--text">Welcome to</div>
                                        <div class="text-h4 text-sm-h5 font-weight-black text-center primary--text">{{ schoolName }}</div>        
                                        <v-card-text>
                                            <div class="primary--text text-h5 text-center">Student Portal Sign in</div>                                            
                                        </v-card-text>

                                        <v-form 
                                            class="pa-5"
                                            @submit.prevent
                                        >
                                            <v-text-field
                                                v-show="!reset && !firstLogin"
                                                label="Student ID"                                                
                                                name="id"                                                
                                                type="text"
                                                color="primary"
                                                v-model="formData.student_id"
                                                clearable
                                                autofocus
                                                v-on:keyup="keyPressId"                                                  
                                            >
                                                <v-icon
                                                    slot="prepend"
                                                    color="primary"
                                                >
                                                    mdi-account
                                                </v-icon>
                                            </v-text-field> 

                                            <v-text-field
                                                v-show="!reset && !firstLogin"
                                                ref="password"
                                                label="Password"
                                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                                @click:append="show = !show"   
                                                :type="show ? 'text' : 'password'"                                                
                                                :hint="passwordHint"
                                                persistent-hint                                                                                                   
                                                name="password"
                                                v-model="formData.password"
                                                v-on:keyup="keyPressPassword"
                                                clearable                                                        
                                            >
                                                <v-icon
                                                    slot="prepend"
                                                    color="primary"
                                                >
                                                    mdi-key
                                                </v-icon>
                                            </v-text-field>                                        
                                    
                                           

                                            <div 
                                                class="text-right mt-6"
                                                v-show="!reset && !firstLogin"
                                            >                                                
                                                <v-btn 
                                                    color="primary" 
                                                    @click="submit" 
                                                    dark
                                                    block
                                                >LOGIN</v-btn>
                                            </div>

                                             

                                           <v-text-field
                                                v-show="firstLogin"
                                                label="Birth Certificate Pin / SEA Number"                                                
                                                name="birth_certificate_no"                                                
                                                type="text"
                                                color="primary"
                                                v-model="formData.birth_certificate_no"
                                                clearable
                                                autofocus
                                                v-on:keyup="keyPressId"                                                  
                                            >
                                                <v-icon
                                                    slot="prepend"
                                                    color="primary"
                                                >
                                                    mdi-account
                                                </v-icon>
                                            </v-text-field> 

                                            <v-btn
                                                v-show="firstLogin" 
                                                color="primary" 
                                                @click="submit" 
                                                dark
                                                block
                                            >Login</v-btn> 
                                             
                                            <v-text-field
                                                v-show="reset"
                                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                                :type="show ? 'text' : 'password'"
                                                label="New Password"                                                    
                                                @click:append="show = !show"                                                   
                                                name="password"
                                                v-model="formData.newPassword"
                                            >
                                                <v-icon
                                                    slot="prepend"
                                                    color="primary"
                                                >
                                                    mdi-key
                                                </v-icon>
                                            </v-text-field>

                                            <v-text-field
                                                v-show="reset"
                                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                                :type="show ? 'text' : 'password'"
                                                label="Confirm New Password"                                                    
                                                @click:append="show = !show"                                                    
                                                name="password"
                                                v-model="formData.confirmPassword"
                                            >
                                                <v-icon
                                                    slot="prepend"
                                                    color="primary"
                                                >
                                                    mdi-key
                                                </v-icon>
                                            </v-text-field>

                                            <v-btn
                                                v-show="reset" 
                                                color="primary" 
                                                @click="resetPassword" 
                                                dark
                                                block
                                                class="mt-6"
                                            >Change Password</v-btn>                                                    
                                        </v-form>        
                                    </v-col>
                                </v-row>

                                <v-card-actions>
                                    <div>
                                        <v-btn
                                            v-if="studentIdQuery"
                                            text
                                            color="indigo darken-4"
                                            class="text-caption mt-5"
                                            @click="openDialog(true)"
                                        >
                                            Click here to get your student ID
                                        </v-btn>
                                    </div>    
                                </v-card-actions>
                                  
                                                                 
                               
                            </v-card>
                        </v-expand-transition>
                    </v-col>
                </v-row>
                <v-overlay
                    :value="getOverlay"
                    opacity="0.8"
                    z-index="10"                    
                >
                    
                    <v-progress-circular
                        indeterminate
                        size="64"
                    ></v-progress-circular>                   
                </v-overlay>
                <query-id/>
            </v-container>            
        </v-main>        
    </v-app>
</template>

<script>
import QueryId from '../QueryId'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    name: 'Login',
    components: {
        QueryId,
    },
    data(){
        return {            
            show: false,
            userpassword: '',
            formData: {
                student_id: '',
                password: '',
                newPassword: '',
                confirmPassword: '',
                changedPassword: '',
            },
            password: true,
            studentIdQuery: false,            
            passwordLabel: 'Date of Birth',            
            windowTitle: 'yyyymmdd',
            resetPasswordErrors: '',
            expand: true,
            reset: false, 
            firstLogin: false,                             
        }
    },

    props: {
        source: String,
    },

    watch: {
        userpassword: {
            handler(val){
                this.formData.student_id = val;                
            }
        },              
    },

    computed: {
        ...mapGetters({
            getOverlay: 'auth/getOverlay',
            schoolName: 'form/getSchoolName',
            primaryColor: 'form/getPrimaryColor',
            colorDarken: 'form/getColorDarken',
            getloginErrors: 'auth/getLoginErrors',
            authenticated: 'auth/getAuthenticated',
            getResetPassword: 'auth/getResetPassword',
            admin: 'auth/getAdmin',
            validStudentID: 'auth/getStudentIDValidation',
            step: 'auth/getStep',
            passwordHint: 'auth/getPasswordHint',
            passwordPlaceholder: 'auth/getPasswordPlaceholder',  
            userID: 'auth/getUserID',                     
        }),
        
    },

    methods: {
        ...mapActions({
            signIn: 'auth/signIn',
            signInFirstLogin: 'auth/signInFirstLogin',
            changePassword: 'auth/changePassword',            
            getUser: 'auth/getUser',            
        }),

        ...mapMutations({
            openDialog: 'auth/setDialog',
            setNewPassword: 'auth/setNewPassword',
            setUserID: 'auth/setUserID',
            setStep: 'auth/setStep',
            setOverlay: 'auth/setOverlay',
            setAuthenticated: 'auth/setAuthenticated',
            setUser: 'auth/setUser',            
        }),

        keyPressPassword (e) {            
            if(e.keyCode === 13){
                this.submit();
            }
        },

        keyPressId (e) {
            if(e.keyCode === 13){
                this.$refs.password.focus();
            }
        }, 
        
        async submit () {                     
            console.log('Authenticating..');

            if(!this.formData.student_id && this.formData.birth_certificate_no){
                //first time login
                await this.signInFirstLogin(this.formData)
                this.formData.student_id = this.userID;
            } else{
                await this.signIn(this.formData);
            }

            console.log(`Authenticated: ${this.authenticated}`)
            this.setUser({
                student_id : this.formData.student_id
            })
            if(this.getResetPassword && this.authenticated && !this.admin){               
                this.reset = true;
                this.firstLogin = false;
                this.setAuthenticated(false);
                sessionStorage.removeItem('authenticated');
                this.formData.password = '';               
            }                       
            else if(this.authenticated){                
                this.$router.replace('/dashboard');
            }
            else{
                this.loginErrors = this.getLoginErrors 
            }
            this.setOverlay(false);                     
        },

        async resetPassword () {
            console.log("Changing password...");
            //console.log(`Confirm Password: ${this.formData.confirmPassword}`);
            console.log(`New Password: ${this.formData.newPassword}`)
            if(this.formData.newPassword === this.formData.confirmPassword){
                //console.log(`Confirm Password: ${this.formData.confirmPassword}`)
                this.setNewPassword(this.formData.confirmPassword);
                try {
                    const { data } = await this.changePassword();
                    this.setAuthenticated(true);                    
                    sessionStorage.authenticated = data.name;   
                    this.$router.replace('/dashboard');
                } catch (error) {
                    if(error.response) console.log(error.response);
                    else console.log(error);
                }                
                this.setOverlay(false);                
            }
            
        },

        firstLoginPage () {
            console.log('first login')
            this.firstLogin = true;
        }
        
    }
}
</script>

<style scoped>
    .container-background{
        background-position: center center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        filter: brightness(40%);
        position: absolute;
    }    

</style>