<template>
    <v-app>    
        <v-main>            
            <v-container class="fill-height" fluid>
                <v-row align="center" justify="center" style="z-index:10">
                    <v-col cols="6" >
                        <v-card class="elevation-12">
                            <v-window v-model="step">
                                <v-window-item :value="1"> 
                                    <v-row no-gutters>                                         
                                        <v-col cols="12" >
                                            <v-card-text class="mt-10">
                                                <div class="text-h4 text-left cyan--text text--darken-3">Change Password</div>                                            
                                            </v-card-text>
                                            <v-form class="pa-5">
                                                <v-text-field
                                                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                                    :type="show ? 'text' : 'password'"
                                                    label="Old Password"                                                    
                                                    @click:append="show = !show"                                                   
                                                    name="password"
                                                    v-model="formData.password"
                                                >
                                                    <v-icon
                                                        slot="prepend"
                                                        color="cyan darken-3"
                                                    >
                                                        mdi-key
                                                    </v-icon>
                                                </v-text-field>
                                                <v-text-field
                                                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                                    :type="show ? 'text' : 'password'"
                                                    label="New Password"                                                    
                                                    @click:append="show = !show"                                                    
                                                    name="password"
                                                    v-model="formData.password"
                                                >
                                                    <v-icon
                                                        slot="prepend"
                                                        color="cyan darken-3"
                                                    >
                                                        mdi-key
                                                    </v-icon>
                                                </v-text-field>
                                            </v-form>
                                            <div class="text-right mt-3 mr-4 mb-4">
                                                <v-btn color="cyan darken-3" @click="submit" dark>Change Password</v-btn>
                                            </div>
                                        </v-col>
                                       
                                    </v-row>
                                </v-window-item>
                                <v-window-item :value="2">

                                </v-window-item>
                            </v-window>
                        </v-card>
                    </v-col>
                </v-row>
                <v-overlay
                    :value="getOverlay"
                    opacity="0.8"
                    z-index="10"                    
                >
                    
                    <v-progress-circular
                        indeterminate
                        size="64"
                    ></v-progress-circular>                   
                </v-overlay>
            </v-container>            
        </v-main>
        
    </v-app>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'Login',
    data(){
        return {
            step: 1,
            show: false,
            formData: {
                student_id: '',
                password: '',
            },
            primaryColor: process.env.VUE_APP_THEME_COLOR_PRIMARY,
            colorDarken: process.env.VUE_APP_THEME_COLOR_DARKEN,              
        }
    },        
    props: {
        source: String,
    },
    computed: {
        ...mapGetters({
            getOverlay: 'auth/getOverlay'
        })
    },
    methods: {
        ...mapActions({
            signIn: 'auth/signIn'
        }),
        async submit () {           
            //step++        
            await this.signIn(this.formData);
            
            //this.$router.replace({name: 'home'});
            this.$router.replace('/registration/student-data');                        
        },
        
    }
}
</script>

<style scoped>
    .container-background{
        background-position: center center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        filter: brightness(40%);
        position: absolute;
    }    

</style>