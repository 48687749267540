 <template>
    <span  v-if="saveStatus === 'saving'">
        <v-fade-transition>
            <v-progress-circular
                
                size="16"
                width="3"
                color="info"
                indeterminate
            ></v-progress-circular>
        </v-fade-transition>
    </span>

    <span v-else-if="saveStatus === 'success'">
        <v-fade-transition>    
            <v-icon
                
                small
                color="info"                                        
            >
                mdi-check-all
            </v-icon>
        </v-fade-transition>
    </span>

    <span v-else-if="saveStatus === 'error'">
        <v-fade-transition>    
            <v-icon
                
                small
                color="red"                                        
            >
                mdi-alert-circle
            </v-icon>
        </v-fade-transition>
    </span>    
</template>

<script>
export default {
    props: {
        saveStatus: {
            type: String,
            default: ''
        }
    },

    // watch: {
    //     saveStatus: {
    //         handler (val) {
    //             console.log(`Autosave: ${val}`)
    //         }
    //     }
    // }
   
}
</script>