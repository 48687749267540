<template>
    <v-app >
        <v-app-bar                                 
            color="primary"
            dark
            absolute
            hide-on-scroll
            scroll-target="#scroll-target"
        >
            <div class="d-flex align-center secondary--text font-weight-bold">
                <v-img
                    alt="Vuetify Logo"
                    class="shrink mr-2"
                    contain
                    src="../../assets/logo.png"
                    transition="scale-transition"
                    width="60"
                />
                {{ schoolName}}                
            </div>

            <v-spacer></v-spacer>

            <v-tooltip 
                bottom
                color="primary"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-avatar                
                        color="secondary"
                        class="mr-6 d-none d-sm-flex"
                        size="36"                
                        v-bind="attrs"
                        v-on="on"
                                       
                    >
                        <span class="primary--text">{{ user.initials }}</span>
                    </v-avatar>
                </template>
                {{ user.name}}
            </v-tooltip>    

            <logout
                class="d-none d-md-flex"
            ></logout>

            <v-app-bar-nav-icon
                class="d-flex d-md-none"
                @click.stop="drawer = !drawer"
            ></v-app-bar-nav-icon>          

        </v-app-bar>              
 
        <v-main>            
            <v-sheet                
                id="scroll-target"
                height="100vh" 
                class="overflow-y-auto grey lighten-5"
            >  
                <v-card
                    v-if="showMenu"
                    width="150"
                    id="speed-dial"
                    flat
                    color="grey lighten-5"
                    class="d-none mt-10 ml-4 d-md-flex flex-column pt-2"
                >
                    <v-speed-dial
                        v-model="fab"                        
                        direction="bottom"
                        transition="slide-y-reverse-transition"
                        class="d-none d-md-flex align-start"
                    >
                        <template
                            v-slot:activator
                        >
                            <v-fab-transition>
                                <v-btn                                    
                                    v-model="fab"
                                    color="primary"
                                    fab
                                >
                                    <v-icon v-if="fab">
                                        mdi-close
                                    </v-icon>
                                    <v-icon v-else>
                                        mdi-menu
                                    </v-icon>
                                </v-btn>
                            </v-fab-transition>    
                        </template>

                        <v-btn
                            v-for="(item, index) in getNavigationMenus"
                            :key="index"                            
                            small
                            color="primary"
                            text
                            class="ml-4 pa-0"
                            :to="item.route"                            
                        >
                            <v-avatar 
                                color="primary"
                                size="24"
                                class="mr-2"
                            >
                                <v-icon 
                                    small 
                                    color="white"                                   
                                >
                                    {{ item.icon }}
                                </v-icon>
                            </v-avatar>
                            {{ item.title }}
                        </v-btn>

                    </v-speed-dial>
                    <div
                        v-if="!fab"
                        class="caption mt-3 d-flex ml-3"
                        style="position:relative"
                    >
                        Menu
                    </div>
                </v-card>
                
                <v-container 
                    fluid
                    class="mt-16 pt-10 grey lighten-5"                    
                >
                    <slot></slot>
                </v-container>                    
            </v-sheet>
        </v-main>
        
        <v-overlay
            :value="overlay"
            opacity="0.8"
            z-index="10"                    
        >
            
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>                   
        </v-overlay>

         <v-navigation-drawer
            v-if="drawer"
            v-model="drawer"
            absolute
            bottom
            temporary
        >
            <v-list
                nav                    
            >
                <v-list-item-group
                    v-model="group"
                    active-class="primary--text"
                    color="primary"
                >
                    <v-list-item
                        v-for="(item, index) in navigationMenus"
                        :key="index"
                        :to="item.route"
                    >
                        <v-list-item-icon>
                            <v-icon>
                                {{ item.icon }}
                            </v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>
                            {{ item.title }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        
        </v-navigation-drawer>

    </v-app>
</template>

<script>
import Logout from '../auth/Logout';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { isBefore, parseISO } from 'date-fns';

export default {
    name: 'App',

    components: {    
        Logout,
        
    },

    created: function () {
        this.initialize();
    },
   
    data: () => ({       
        drawer: false, 
        navigationMenus: [
            {title: "Dashboard", icon: "mdi-view-dashboard", route: '/dashboard'},
            {title: "Term Reports", icon: "mdi-file-certificate", route: '/term-reports'},            
            {title: "Edit/View Registration", icon: "mdi-keyboard", route: '/registration/student-data'},            
            {title: "Logout", icon: "mdi-logout", route: '/logout'},
        ],
        group: null,        
        fab: false,
              
    }),

    watch: {
        group () {
            this.drawer = false;
        }
    },
 
    computed: {
        ...mapGetters({
            user: 'auth/getUser',
            schoolName: 'form/getSchoolName',
            overlay: 'auth/getOverlay', 
            studentRecord: 'auth/getStudentRecord',                        
        }),

        showMenu () {
            if(this.$route.name == 'dashboard') return false;
            else return true;
        },

        getNavigationMenus () {
            return this.navigationMenus.filter(value => {
                if(value.route != this.$route.path) return value;
            })
        }
        
    },

    methods: { 
        ...mapMutations({
            setOverlay: 'auth/setOverlay',
            setEditRegistration: 'auth/setEditRegistration',
            setStudentFormLevel: 'auth/setStudentFormLevel'
        }),

        ...mapActions({
            getStudentData: 'auth/getStudent',
            getRegistrationStatus: 'auth/getRegistrationStatus',
            getFormClasses: 'auth/getFormClasses',              
        }),

        async initialize () {
            this.setOverlay(true);
            await this.getStudentData();
            try {
                const { data } = await this.getFormClasses();
                this.parseFormLevel(data);
                const response = await this.getRegistrationStatus();
                this.parseRegistrationStatus(response);
            } catch (error) {
                if(error.response){
                    console.log(error.response);
                    return;
                }
                console.log(error);

            }
            this.setOverlay(false);
        },

        parseFormLevel (data) {
            data.forEach(value => {
                if(value.id == this.studentRecord.form_class_id){
                    this.setStudentFormLevel(value.class_level);
                    return;
                }                
            })
        },
        
        parseRegistrationStatus ({ data }) {
            this.setEditRegistration(false);
            if(!data.deadline && data.open === 1){
                this.setEditRegistration(true);
                return;
            }

            if(data.deadline && data.open === 1){
                const deadline = parseISO(data.deadline)
                const today = new Date();
                if(isBefore(today,deadline)){
                    console.log('date before deadline')
                    this.setEditRegistration(true);
                    return;
                }
                
            }

        }
    },

  
};
</script>

<style scoped>
    ::v-deep .v-list-item.v-list-item--link.theme--light{
        border-left: 0px;
    }

    ::v-deep #speed-dial {
        position: fixed;
        top: 64px;
    }

    ::v-deep .v-speed-dial__list{
        align-items: start;
    }
</style>


