import axios from 'axios';

class UploadFileService {
    upload(file, id, file_type, onUploadProgress) {
        let formData = new FormData();

        formData.append("file", file);       
        formData.append('id', id);
        formData.append('file_type', file_type);

        return axios.post("/api/store-file", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress
        });
    }

    getFiles(student_id) {
        return axios.get(`/api/get-files?student_id=${student_id}`)
    }
}

export default new UploadFileService();